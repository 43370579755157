<template>
    <section class="notification-row"
             ref="container"
             tabindex="-1"
             @click="expand()"
             @keydown.esc.stop="expand(false)"
             @mouseenter="showTooltip"
             :class="{expanded, disabled: !enabledAndEditable, 'notification-row--deleted': notification.deleted, 'can-subscribe': enabled}">
        <span class="notification-row__title notification-row__data" :class="{'notification-row__title--empty': !notification.name}">
            <training-marker v-if="nextStep.id === 'NAME'" class="marker"></training-marker>
            <inline-text-input v-if="nameEditMode"
                               class="notification-row__name-editor"
                               v-model.trim="tempName"
                               ref="nameEditInput"
                               placeholder="Name this notification"
                               :disabled="!enabledAndEditable"
                               @cancel="cancelEditName"
                               @ok="updateName"/>
            <span v-else>
                <span v-if="notification.name"
                      class="notification-row__title-field">
                    {{notification.name}}
                    <old-tooltip label="Click to edit this notification's name">
                        <i v-if="!nameEditMode" class="symbol-edit"
                           @click.stop="editName"></i>
                    </old-tooltip>
                </span>
                <em v-else
                    class="notification-row__title-field"
                    @click.stop="editName">
                    No name
                    <i v-if="!nameEditMode" class="symbol-edit"
                       @click.stop="editName">
                    </i>
                </em>
            </span>

            <slotted-tag v-if="!notification.active && !notification.deleted"
                         class="notification-row__inactive"
                         close-tooltip="Click to activate this notification"
                         :disabled="!enabledAndEditable"
                         @click.stop
                         @close="activateNotification">Inactive</slotted-tag>
            <slotted-tag v-if="notification.deleted"
                         class="notification-row__inactive"
                         @click.stop
                         no-close>Deleted</slotted-tag>
            <slotted-tag v-if="isNew"
                         class="notification-row__inactive"
                         tooltip="This notification has unsaved changed"
                         @click.stop
                         no-close>
                <i class="symbol-warning"></i>Unsaved changes
            </slotted-tag>
            <slotted-tag v-if="notification.reportId && !expanded"
                         class="notification-row__inactive notification-row__dashboard-tag"
                         tooltip="This notification has an attached dashboard"
                         @click.stop="viewDashboard()"
                         no-close>
                <i class="symbol-reports"></i>
            </slotted-tag>
            <slotted-tag v-if="notification.anonymise && !expanded"
                         class="notification-row__inactive notification-row__dashboard-tag"
                         tooltip="This notification is sent out with anonymised mentions"
                         @click.stop
                         @close="setAnonymise(false)">
                Anonymised
            </slotted-tag>
            <slotted-tag v-if="warnings.length"
                         class="notification-row__inactive"
                         no-close
                         @click.stop="showWarnings"
                         tooltip="Click to see warnings" style="cursor: pointer">
                <warning-indicator :warnings="warnings"
                                   ref="warning"
                                   class="notification-row__warnings"/>Has warnings
            </slotted-tag>
            <transition name="fade">
                <notification-saving class="notification-row__tag" v-if="updating || (!isNew && timerPending)"/>
            </transition>
        </span>
        <span class="notification-row__data notification-row--hide-expanded">
            <span v-if="!isSubscribed" class="notification-row__data--muted">
                <be-button link tooltip="Click to subscribe" @click.stop="subscribe">Not subscribed</be-button>
            </span>
            <slotted-tag v-else
                         @close="unsubscribe"
                         close-tooltip="Click to unsubscribe"
                         :disabled="!enabled">
                <animated-check :animated="justSubscribed" /> Subscribed
            </slotted-tag>
        </span>
        <span class="notification-row__data notification-row--hide-expanded"
              :class="{'notification-row__data--muted': !numRecipientsInAccount || !notification.active}">
            <old-tooltip label="The number of people subscribed to this email notification">
                <deq-number :number="numRecipientsInAccount"/> {{formatPlural(numRecipientsInAccount, "recipient") }}
            </old-tooltip>
        </span>
        <span class="notification-row__data notification-row--hide-expanded notification-row__data--muted notification-row__data--hide-small">
            <old-tooltip label="The day and time this notification was last updated">
                <deq-date v-if="notification.lastUpdated" :date="notification.lastUpdated"/>
            </old-tooltip>
        </span>
        <span v-if="expanded" class="notification-row__data notification-row__close">
            <span v-if="expanded && user.debugMode" class="notification-row__debug">
                <popup-menu>
                    <template #activator>
                        <span>🐛</span>
                    </template>
                    <div class="mini-menu">
                        <ul>
                            <li>Debug options
                            <li>Notification id: {{notification.id}}</li>
                        </ul>
                        <a @click.stop="previewInCorn()">Preview in corn</a>
                        <a v-if="notification.reportId" @click.stop="viewDashboard()">View dashboard</a>
                    </div>
                </popup-menu>
            </span>
            <be-button link
                       tooltip="Click to close (esc)"
                       @click.stop="expand(false)">
                <i class="symbol-close"></i>
            </be-button>
        </span>



        <div class="notification-row__info" v-if="expanded" @click.stop>
            <section class="notification-row__recipients">
                <section>
                    <h4><training-marker v-if="nextStep.id === 'DESCRIPTION'"/> What this notification does</h4>

                    <inline-text-input v-if="descriptionEditMode"
                                       v-model.trim="tempDescription"
                                       ref="descriptionEditInput"
                                       placeholder="Describe this notification"
                                       :disabled="!enabledAndEditable"
                                       @ok="updateDescription"
                                       @cancel="cancelEditDescription"/>
                    <section v-else>
                        <p v-if="notification.description" class="notification-row__description"
                           @click="editDescription">
                            {{notification.description}} <i class="symbol-edit"/>
                        </p>
                        <p v-else class="notification-row__description notification-row__description--empty"
                           @click="editDescription">
                            <em>Add a description to be included in the email <i class="symbol-edit"/></em>
                        </p>
                    </section>
                </section>

                <section>
                    <h4>Who receives these emails</h4>
                    <notification-recipients
                            :notification="notification"
                            @unsubscribe="unsubscribe()"
                            @subscribe="subscribe()"
                            @remove="removeRecipient($event)"
                            @add="addRecipient($event)"
                            :editable="canEditNotifications"
                            :disabled="!enabled"/>
                </section>
            </section>
            <section class="notification-row__settings">
                <section>
                    <h4>When to send</h4>
                    <notification-time-selector
                            :notification="notification"
                            :disabled="!enabledAndEditable"
                            v-model="timerPending"
                            @update="saveTimes($event)"/>
                </section>

                <section class="notification-row__filter-section">
                    <h4>
                        <training-marker v-if="nextStep.id === 'SAVE'"/>{{titleForWhatIsSent}}
                    </h4>
                    <section v-if="shouldShowFilter"
                             class="notification-row__filter_text">

                        <notification-filter-editor
                            :notification="notification"
                            :disabled="!enabledAndEditable"
                            @update="updateFilter($event)"
                    />

                    </section>
                    <section v-else-if="notification.type === 'BRAND_SUMMARY'"
                             @click="selectBrands"
                             class="notification-row__selected-brands">
                        <!-- Brand summary filters do almost nothing except define what brands to use. -->
                        Summarises
                        <span v-if="!brandsInFilter || !brandsInFilter.length">
                            all brands in this account
                        </span>
                        <span v-for="(brand, index) in brandsInFilter" :key="brand.id">
                            <strong>{{formatBrandName(brand)}}</strong><span v-if="index !== brandsInFilter.length - 1">, </span>
                        </span>
                        <i class="symbol-edit"></i>
                    </section>
                </section>

                <section>
                    <section v-if="shouldShowDashboardPicker">
                        <h4>What dashboard to include</h4>
                        <span v-if="notification.reportId">
                            <slotted-tag no-close
                                         class="notification-row__dashboard-tag"
                                         tooltip="Click here to view this dashboard"
                                         @click="viewDashboard">
                                <i class="symbol-reports"></i>
                            </slotted-tag>
                            <slotted-tag @click="selectDashboard"
                                         @close="updateField('reportId', 0, 'dashboard report', 'This notification will no longer send a dashboard')"
                                         class="notification-row__dashboard-tag"
                                         tooltip="Click to edit the attached dashboard"
                                         close-tooltip="Click to remove this dashboard from the notification"
                                         :disabled="!enabledAndEditable">
                                {{dashboardName}}
                            </slotted-tag>
                            with
                            <popup-menu>
                                <template #activator>
                                    <slotted-tag class="notification-row__dashboard-tag"
                                                 close-tooltip="Click to stop showing example mentions"
                                                 @close="updateField('maxExampleMentions', 0, 'example mention count')">
                                        <span v-if="notification.maxExampleMentions !== 0">{{notification.maxExampleMentions || 50}} example {{formatPlural((notification.maxExampleMentions || 50), 'mention')}}</span>
                                        <span v-else>no example mentions</span>
                                    </slotted-tag>
                                </template>

                                <div class="mini-menu">
                                    <a v-for="val in exampleMentionsOptions"
                                       :key="val.id"
                                       @click="updateField('maxExampleMentions', val.id, 'example mention count')">
                                        {{val.name}}
                                    </a>
                                </div>
                            </popup-menu>

                        </span>

                        <span v-else
                              class="notification-row__no-dashboard"
                              @click="selectDashboard">
                            Click here to add a dashboard (optional) <i class="symbol-edit"></i>
                        </span>
                        <div v-if="dashboard">
                            <!-- have to use == for this as it is sometimes a number and sometimes a string  -->
                            <div v-if="dashboard['max-width'] == 6" class="notification-row__dashboard_info">
                                This dashboard has a width of 6 so it will be included in the notification email body
                            </div>
                            <div v-else class="notification-row__dashboard_info">
                                This dashboard is too wide to be included in the email body (max allowed width is 6)
                                and will be attached as a PDF instead
                            </div>
                        </div>
                    </section>
                </section>
            </section>

            <footer class="notification-row__general">
                <span v-if="!isNew && notification.created" class="notification-row__data--hide-small">
                    <span class="notification-row__label">Created: </span><deq-date :date="notification.created"/>
                </span>
                <span v-if="!isNew && notification.createdBy">
                    <span class="notification-row__label">Created by: </span>
                    <deq-user :user="notification.createdBy"/>
                </span>
                <span v-else-if="isNew">
                    <training-marker v-if="nextStep.id !== 'SAVE'"></training-marker>
                    <span v-html="nextStep.message"></span>
                </span>
                <span v-if="notification.lastUpdated" class="notification-row__data--hide-small">
                    <span class="notification-row__label">Last updated: </span>
                    <deq-date :date="notification.lastUpdated"/>
                </span>
                <span v-if="notification.lastUpdatedBy">
                    <span class="notification-row__label">Last updated by:</span> <deq-user :user="notification.lastUpdatedBy"/>
                </span>
                <section class="notification-row__danger">
                    <be-button v-if="user.admin && notification.active && !notification.anonymise"
                               :disabled="!enabledAndEditable"
                               tooltip="Click to have this notification's mentions anonymised when the notification is sent out"
                               @click="setAnonymise(true)"
                               link>
                        Anonymise
                    </be-button>
                    <slotted-tag v-else-if="user.admin && notification.active"
                                 :disabled="!enabledAndEditable"
                                 tooltip="This notification is sent out with mentions anonymised"
                                 @close="setAnonymise(false)"
                                 link>
                        Anonymised
                    </slotted-tag>

                    <be-button v-if="notification.active && !isNew"
                               tooltip="Click here if you would like to stop this notification from sending too all recipients"
                               :disabled="!enabledAndEditable"
                               @click="deactivateNotification()">
                        Deactivate
                    </be-button>
                    <be-button v-if="!notification.active && !isNew"
                               tooltip="Click here if you would like to start sending all recipients emails from this notification"
                               :disabled="!enabledAndEditable"
                               @click="activateNotification">
                        Make active
                    </be-button>
                    <be-button v-if="!notification.active && !isNew"
                               :danger="true"
                               tooltip="Click here to permanently delete this notification"
                               :disabled="!enabledAndEditable"
                               @click="deleteNotification">
                        Delete
                    </be-button>
                    <be-button link
                               v-if="isNew"
                               tooltip="Cancel the creation of this new notification"
                               @click="cancelCreation"
                               :disabled="updating">
                        Cancel
                    </be-button>
                    <be-button v-if="isNew" primary @click="saveNew"
                               tooltip="Click here to save these settings and to start sending these notifications"
                               :disabled="updating || setupNotComplete">
                        Create
                    </be-button>
                </section>
            </footer>
        </div>
    </section>
</template>

<script>
import OldTooltip from "@/components/tooltip/OldTooltip";
import DeqNumber from "@/components/formatters/DeqNumber";
import {showTooltipText} from "@/components/tooltip/TooltipUtilities";
import DeqDate from "@/components/formatters/DeqDate";
import BeButton from "@/components/buttons/BeButton";
import SlottedTag from "@/components/tags/SlottedTag";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import {notificationMixin} from "./NotificationMixins";
import WarningIndicator from "@/components/WarningIndicator";
import NotificationTimeSelector from "./NotificationTimeSelector";
import NotificationSaving from "./NotificationSaving";
import NotificationRecipients from "./NotificationRecipients";
import NotificationFilterEditor from "./NotificationFilterEditor";
import PopupMenu from "@/components/PopupMenu";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import DashboardPickerDialog from "@/app/framework/dialogs/dashboard-picker/DashboardPickerDialog";
import {notifyWithText} from "@/app/framework/notifications/Notifications";
import TrainingMarker from "@/components/inputs/TrainingMarker";
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {mapActions, mapGetters} from "vuex";
import {formatBrandName, formatPlural} from "@/app/utils/Format";
import {escapeExpression} from "@/app/utils/StringUtils";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {getNotificationWarnings} from "@/store/vuex/dataChecks";
import DeqUser from "@/components/formatters/DeqUser";

export default {
        mixins: [notificationMixin],

        components: {
            DeqUser,
            DeqNumber,
            DeqDate,
            OldTooltip,
            AnimatedCheck,
            NotificationFilterEditor,
            NotificationRecipients,
            NotificationSaving,
            WarningIndicator,
            InlineTextInput, SlottedTag, BeButton,
            NotificationTimeSelector, PopupMenu,
            TrainingMarker
        },


        props: {
            notification: {
                type: Object,
                required: true
            },
            focus: Boolean
        },

        data() {
            return {
                timerPending: false,
                filterEditMode: false,
                editRecipientMode: false,
                dashboard: null,
                exampleMentionsOptions: EXAMPLE_MENTIONS_OPTIONS
            }
        },

        watch: {
            expanded() {
                if (!this.notification.id) {
                    this.$set(this.notification, "expanded", this.expanded);
                } else {
                    this.updateNotificationFields({id: this.notification.id, expanded: this.expanded});
                }

                if (!this.expanded) {
                    this.descriptionEditMode = false;
                    this.nameEditMode = false;
                    this.tempDescription = '';
                    this.tempName = '';
                }
            },

            focus() {
                this.handleFocus()
            },

            'notification.reportId'(id) {
                this.dashboard = id > 0 ? this.idToDashboard.get(id) : null
            },

            'notification.expanded'() {
                this.expanded = this.notification.expanded;
            }
        },

        computed: {
            ...mapGetters('dashboards', ['idToDashboard']),
            ...mapGetters(['idToBrand']),

            dashboardName() {
                return (this.dashboard && !this.dashboard.deleted && this.dashboard.name) || (this.notification.reportName ?? null);
            },

            titleForWhatIsSent() {
                switch (this.notification.type) {
                    case "BRAND_SUMMARY":
                        return "What brands does this summarise"

                    case "NOTIFICATION":
                    case "MENTION_DIGEST":
                    case "TRADITIONAL_MEDIA":
                    default:
                        return "What mentions to include";
                }
            },

            shouldShowFilter() {
                return this.notification.type === "NOTIFICATION"
                    || this.notification.type === "MENTION_DIGEST"
                    || this.notification.type === "SPIKE_ALERT"
                    || this.notification.type === "TRADITIONAL_MEDIA"
                    ;
            },

            shouldShowDashboardPicker() {
                return this.notification._dashboard
                    || this.notification.reportId
                    || this.notification.type === "MENTION_DIGEST"
                    || this.notification.type === "BRAND_SUMMARY"
                ;
            },

            numRecipients() {
                if (this.notification.deleted) return 0;
                return (this.notification.recipients || []).length;
            },

            setupNotComplete() {
                const name = (this.notification.name || "").trim();
                const description = (this.notification.description || "").trim();

                return !name.length || !description.length || !!this.warnings?.length;
            },
            nextStep() {
                if (!this.isNew) return { id: null }
                if (!this.notification.name || !this.notification.name.trim().length) {
                    return {id: "NAME", message: "Give this notification a name"};
                }

                if (this.warnings?.length) {
                    return {id: "WARNING", message: "Please resolve the warnings"};
                }


                const description = (this.notification.description || "").trim();
                if (!description.length) {
                    return { id: "DESCRIPTION", message: "Describe what this notification does" };
                }

                let refineYourFilter = "refine your filter";
                if (this.notification.type === "BRAND_SUMMARY") {
                    refineYourFilter = "double check the brands you want summaries for"
                } else if (this.notification.reportId) {
                    refineYourFilter = "check that your chosen dashboard is correct"
                }
                return { id: "SAVE", message: `Choose when the notification is sent and ${refineYourFilter}. Click <em>Create</em> when you're done.` };
            },

            brandsInFilter() {
                if (!this.notification.filter) return [];
                const brands = getBrandsInFilter(this.notification.filter);

                if (brands.include.length) return brands.include.map(id => this.idToBrand.get(id));

                return [];
            }
        },

        created() {
            this.dashboard = this.notification?.reportId ? this.idToDashboard.get(this.notification.reportId) : null;
        },

        mounted() {
            if (this.$refs.container && this.expanded) {
                // Grab focus so that we know that the escape key will work.
                // Don't focus on none-expanded elements, since that will cause the whole page to scroll.
                this.$refs.container.focus();
            }
            this.checkWarnings();
            this.handleFocus();
        },

        methods: {
            formatBrandName,
            formatPlural,

            ...mapActions('digests', ['updateNotificationFields']),

            handleFocus() {
                if (this.focus) {
                    this.expand(true);
                    this.$nextTick(() => this.$refs.container?.scrollIntoView({behavior: "smooth", block: "center"}));
                }
            },

            selectDashboard() {
                if (!this.enabledAndEditable) return;
                const dialog = showDialog(DashboardPickerDialog, {value: this.notification.reportId ?? 0, optional: true});
                dialog.title = "Dashboard included with the notification";

                dialog.$on('input', async selected => {
                    dialog._closeDialog();
                    const d = this.idToDashboard.get(selected);
                    if (this.notification.id) {
                        this.$set(this.notification, "maxExampleMentions", this.notification.maxExampleMentions ?? 0)
                    } else if (!this.notification.maxExampleMentions) {
                        await this.updateNotificationFields({id: this.notification.id, maxExampleMentions: 0});
                    }

                    await this.updateField("reportId", selected, "dashboard",
                        d.name
                            ? `The notification is now sending dashboard <strong>${escapeExpression(d.name)}</strong>`
                            : null
                    );
                })
            },
            /** Opens a brand selector. Used for the brand summary notifications. */
            selectBrands() {
                if (!this.enabledAndEditable) return;

                const dialog = showDialog(BrandPickerDialog, {selectedIds: this.brandsInFilter.map(b => b.id)});

                dialog.$on('selected', selected => {
                    if (this.notification.type === "BRAND_SUMMARY") {
                        const mainFilter = "published inthelast 24hours and relevancy isnt irrelevant and reshareof is unknown";
                        let filter = mainFilter;
                        if (selected?.length) {
                            filter = `(${mainFilter}) and (${selected.map(b => `brand isorchildof ${b.id}`).join(' or ')})`
                        }
                        this.updateFilter(filter, "The brands this summary email is for has been updated");
                    } else {
                        console.error("Not the correct notification type to update brands");
                    }
                })
            },
            showTooltip(event) {
                if (!this.expanded) {
                    const message = this.notification.deleted ? "This notification is deleted" : "Click to expand and edit this notification's settings";
                    showTooltipText(event.target, message);
                }
            },

            showWarnings() {
                this.$refs.warning.showWarnings();
            },

            async checkWarnings() {
                this.warnings = await getNotificationWarnings(this.notification);
            },

            async saveTimes(previous) {
                if (this.updating || this.isNew) return;

                if (!this.notification.id && !this.notification.days) this.$set(this.notification, 'days', []);
                if (!this.notification.id && !this.notification.times) this.$set(this.notification, 'times', []);

                this.updating = true;
                try {
                    await this.storeUpdateDigest(this.notification);
                    notifyWithText(
                        "Notification times updated",
                        !previous || !this.notification.id ? null : async () => {
                            await this.updateNotificationFields({
                                id: this.notification.id,
                                type: previous.type,
                                times: previous.times,
                                days: previous.days
                            });
                            await this.saveTimes();
                        },
                        "<i class='symbol-notification'></i>");
                } catch (e) {
                    console.error(e);
                    if (this.notification.id) {
                        await this.updateNotificationFields({
                            id: this.notification.id,
                            type: previous.type,
                            times: previous.times,
                            days: previous.days
                        });
                    }
                    await showErrorDialog("We were unable to update the notification's times");
                }
                finally {
                    this.updating = false;
                }
            }
        }
    }

    const EXAMPLE_MENTIONS_OPTIONS = [
        { id: 0, name: "None"},
        { id: 1, name: "1"},
        { id: 3, name: "3"},
        { id: 5, name: "5"},
        { id: 10, name: "10 "},
        { id: 25, name: "25 "},
        { id: 50, name: "50 "},
    ]
</script>

<style lang="sass">
    .notification-row .tag
        max-width: 100%
        .tag-picker-name
            max-width: 250px
</style>

<style scoped lang="sass">
    .notification-row
        transition-property: background, margin         /* update property for the .expanded modifier as well */
        transition-duration: var(--transition-duration)
        background: #333
        border-top: var(--border)
        border-bottom: var(--border)
        border-right: var(--border)
        & + .notification-row
            border-top: none
        &.expanded
            transition-property: margin /* Make sure background doesnt transition here, or the flicker is awful. */
            background: #444
            margin-top: 20px
            margin-bottom: 20px
            border-top: var(--border)
            box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4)
        &:not(.expanded):hover
            background: var(--background-menu-hover) /* #383838 */
            .notification-row__data--muted, .notification-row__data--muted ::v-deep .btn-link:not(:hover)
                color: var(--be-colour-light-grey)
        &:focus
            outline: none

        &--deleted
            filter: grayscale(0.8)
            background: #5555

    .notification-row.expanded + .notification-row:not(.expanded)
        border-top: var(--border)

    .notification-row__inactive
        font-size: 1rem
        line-height: 1.3rem
        color: var(--be-colour-muted-text-dark)

    .notification-row__data
        cursor: pointer
        padding: 10px
        border-right: var(--border)
        &:first-child
            border-left: var(--border)
        &:last-child
            border-right: none

    .notification-row__data--muted
        color: var(--be-colour-muted-text-dark)


    .notification-row__data--hide-small
        @media (max-width: 800px)
            display: none

    .expanded .notification-row--hide-expanded
        display: none



    .notification-row__title
        .be-tooltip
            display: inline-block
            width: auto
            height: auto
        .symbol-edit
            font-size: 1rem
            transition: opacity var(--transition-duration)
            opacity: 0

    .expanded
        .notification-row__title
            font-size: 1.8rem
            line-height: 2rem
            padding-left: 20px
            border-right: none
            color: white
            padding-bottom: 15px
            display: inline-block
            &.notification-row__title--empty
                .symbol-edit
                    opacity: 1
        .notification-row__title-field
            cursor: default
            .symbol-edit
                cursor: pointer
            &:hover
                .symbol-edit
                    opacity: 1
        .symbol-edit:hover
            color: var(--be-colour-text-dark__hover)
        .notification-row__inactive
            position: relative
            bottom: 4px // To keep this inline with the expanded header.

    .notification-row
        display: grid
        grid-template-areas: "name subscribed subscriptions date" "info info info info"
        grid-template-columns: var(--grid-columns)

    .notification-row__close
        grid-area: date
        display: flex
        flex-direction: row
        justify-content: flex-end
        & ::v-deep .btn-link
            padding: 0
        @media (max-width: 800px)
            grid-area: subscriptions

    .notification-row__debug
        cursor: pointer

    .notification-row__info
        display: grid
        box-sizing: border-box
        grid-column-gap: 60px
        grid-template-areas: "recipients settings" "footer footer"
        grid-template-columns: 0.5fr 1fr
        grid-template-rows: 1fr auto
        padding: 5px 20px 0
        grid-area: info
        border-left: var(--border)
        min-height: 300px
        @media (max-width: 800px)
            grid-template-areas: "recipients" "settings" "footer"
            grid-template-columns: 1fr

        .notification-row__general
            grid-area: footer
            display: flex
            margin: 10px -20px 0 -20px
            padding: 10px 10px 5px
            border-top: thin solid #333
            background: #3d3d3d
            & > *
                padding-left: 30px
                &:first-child
                    padding-left: 0
            .notification-row__danger
                display: flex
                margin-left: auto
                align-items: flex-start
                .be-button + .be-button, .be-tag + .be-button
                    margin-left: 5px
        .notification-row__recipients
            grid-area: recipients
            display: grid
            grid-template-rows: auto
        .notification-row__settings
            grid-area: settings
            display: grid
            grid-template-rows: auto
            grid-gap: 40px
        .notification-row__filter-section
            .notification-row__filter_text
                color: var(--be-colour-text-dark)
            .notification-row__selected-brands
                color: var(--be-colour-text-dark)
                cursor: pointer
                transition: color var(--transition-duration)
                strong
                    transition: color var(--transition-duration)
                .symbol-edit
                    transition: opacity var(--transition-duration)
                    opacity: 0
                &:hover, &:hover strong
                    color: var(--be-colour-text-dark__hover)
                    .symbol-edit
                        opacity: 1


    .notification-row__label
        color: var(--be-colour-muted-text-dark)

    .notification-row__description
        cursor: pointer
        color: var(--be-colour-text-dark)
        transition-property: color
        transition-duration: var(--transition-duration)
        overflow: hidden
        text-overflow: ellipsis
        //noinspection CssInvalidPropertyValue
        display: -webkit-inline-box
        -webkit-box-orient: vertical
        //noinspection CssUnknownProperty
        -webkit-line-clamp: 3
        &:hover
            color: var(--be-colour-text-dark__hover)

        .symbol-edit
            transition-property: color, opacity
            transition-duration: var(--transition-duration)
            opacity: 1

        &:not(:hover)
            .symbol-edit
                opacity: 0
        &.notification-row__description--empty
            .symbol-edit
                transition: color var(--transition-duration)
                opacity: 1
        &:not(:hover).notification-row__description--empty
            color: var(--be-colour-muted-text-dark)

    .notification-row__dashboard_info
        color: var(--be-colour-muted-text-dark)
        font-style: italic

    .notification-row__counter
        font-family: var(--number-font-family)
        font-size: 0.5rem
        background: var(--be-colour-light-grey)
        color: black
        width: 1rem
        height: 1rem
        display: inline-flex
        text-align: center
        border-radius: 50%
        box-sizing: border-box
        justify-content: center
        align-items: center
        position: relative
        bottom: 5px

    .notification-row__name-editor
        display: inline-flex
        max-width: 70%

    .notification-row__warnings
        display: inline-block
    .notification-row ::v-deep .symbol-warning
        font-size: 1em !important

    .notification-row__no-dashboard
        cursor: pointer
        font-style: italic
        transition: color var(--transition-duration)
        color: var(--be-colour-muted-text-dark)
        &:hover
            color: var(--be-colour-text-dark__hover)
    .disabled .notification-row__no-dashboard
        cursor: not-allowed


    .notification-row__dashboard-tag
        cursor: pointer

    .notification-filter-editor
        display: inline-block

    .disabled.expanded,
    .disabled:not(.can-subscribe)
        .notification-row__title
            cursor: not-allowed
        .notification-row__description
            cursor: not-allowed
        .symbol-edit
            cursor: not-allowed
        .notification-row__filter-section
            .notification-row__selected-brands
                cursor: not-allowed

    .training-marker
        margin-right: 5px
        top: 3px

    h4 .training-marker
        top: 1px

    .notification-row__title .training-marker
        top: -2px

    strong
        color: white
        font-weight: bold

    .be-tag + .be-tag
        margin-left: 5px

    .fade-enter-active, .fade-leave-active
        transition: opacity var(--transition-duration)
    .fade-leave-active
        --transition-duration: 100ms
    .fade-enter, .fade-leave-to
        opacity: 0

    .symbol-checkmark
        color: green


    @media screen and (prefers-reduced-motion: reduce)
        .fade-leave-active
            --transition-duration: 1ms

</style>