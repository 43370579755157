<template>
    <section class="notification-time-selector">
        <section class="notification-time-selector--selectors">
            <be-button :active="isImmediate"
                       v-if="canBeImmediate"
                       tooltip="Send out an email notification as soon as a mention matches this filter"
                       :disabled="disabled"
                       @click="immediateClicked()">
                As mentions come in
            </be-button>
            <section class="notification-time-selector__days">
                <be-button class="notification-time-selector--mon"
                           @click="dayClicked('MON')"
                           tooltip="Send out notifications on Mondays"
                           :disabled="disabled"
                           :active="hasMon">Mon</be-button>
                <be-button @click="dayClicked('TUE')"
                           tooltip="Send out notifications on Tuesdays"
                           :disabled="disabled"
                           :active="hasTue">Tue</be-button>
                <be-button @click="dayClicked('WED')"
                           tooltip="Send out notifications on Wednesdays"
                           :disabled="disabled"
                           :active="hasWed">Wed</be-button>
                <be-button @click="dayClicked('THU')"
                           tooltip="Send out notifications on Thursday"
                           :disabled="disabled"
                           :active="hasThu">Thu</be-button>
                <be-button @click="dayClicked('FRI')"
                           tooltip="Send out notifications on Fridays"
                           :disabled="disabled"
                           :active="hasFri">Fri</be-button>
                <be-button @click="dayClicked('SAT')"
                           tooltip="Send out notifications on Saturdays"
                           :disabled="disabled"
                           :active="hasSat">Sat</be-button>
                <be-button class="notification-time-selector--sun"
                           @click="dayClicked('SUN')"
                           tooltip="Send out notifications on Sundays"
                           :disabled="disabled"
                           :active="hasSun">Sun</be-button>
            </section>
            <section class="notification-time-selector__times" v-if="notification.times && notification.times.length">
                <slotted-tag v-for="time in notification.times"
                             :key="time"
                             :disabled="notification.times.length === 1 || disabled"
                             :tooltip="`Notifications will be sent out on ${formatTime(time)}. Click to choose a different time.`"
                             close-tooltip="Stop sending notifications at this time"
                             @click="replaceTime(time)"
                             @close="removeTime(time)">
                    <span class="notification-time-selector__time"
                          :class="{'notification-time-selector__time--disabled': disabled}"
                    >{{formatTime(time)}}</span>
                </slotted-tag>

                <popup-menu ref="times" v-if="!disabled" bottom>
                    <template #activator>
                        <span class="btn btn-link">
                            <old-tooltip label="Click to add another time">
                                <i class="symbol-add"></i>
                            </old-tooltip>
                        </span>
                    </template>


                    <section class="mini-menu" style="font-family: var(--number-font-family)">
                        <a @click="addTime('0600')">06:00</a>
                        <a @click="addTime('0700')">07:00</a>
                        <a @click="addTime('0800')">08:00</a>
                        <a @click="addTime('0900')">09:00</a>
                        <a @click="addTime('1000')">10:00</a>
                        <a @click="addTime('1100')">11:00</a>
                        <a @click="addTime('1200')">12:00</a>
                        <a @click="addTime('1300')">13:00</a>
                        <a @click="addTime('1400')">14:00</a>
                        <a @click="addTime('1500')">15:00</a>
                        <a @click="addTime('1600')">16:00</a>
                        <a @click="addTime('1700')">17:00</a>
                        <a @click="addTime('1800')">18:00</a>
                        <a @click="addTime('1900')">19:00</a>
                        <a @click="addTime('2000')">20:00</a>
                        <a @click="addTime('2100')">21:00</a>
                        <a @click="addTime('2200')">22:00</a>
                        <a @click="addTime('2300')">23:00</a>
                        <a @click="addTime('0000')">00:00</a>
                    </section>
                </popup-menu>
            </section>
        </section>
        <section class="notification-time-selector--description deq-callout--muted">
            {{description}}
        </section>
    </section>
</template>

<script>
import BeButton from "../../components/buttons/BeButton";
import SlottedTag from "../../components/tags/SlottedTag";
import PopupMenu from "../../components/PopupMenu";
import {mapActions} from "vuex";
import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
        components: {OldTooltip, PopupMenu, SlottedTag, BeButton},
        props: {
            value: {  // Indicates whether pending status is true of not
                type: Boolean,
                default() { return false}
            },
            notification: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean
            }
        },

        data() {
            return {
                handler: null,
                previous: null,
                replace: null,
                originalType: this.notification.type
            }
        },

        watch: {
            disabled(val) {
                if (val && this.$refs.times) {
                    this.$refs.times.showMenu = false;
                }
            }
        },

        computed: {
            nonImmediateType() {
                if (this.originalType === "NOTIFICATION") return "MENTION_DIGEST";
                return this.originalType;
            },

            canBeImmediate() {
                switch (this.notification.type) {
                    case "NOTIFICATION":
                    case "MENTION_DIGEST":
                        // We do not want notifications with dashboards to be firing off every 5 minutes.
                        return !this.notification.reportId;
                    default:
                        return false
                }
            },

            isImmediate() {
                return this.notification.type === "NOTIFICATION";
            },

            hasMon() {
                return !this.isImmediate && this.hasDay("MON");
            },
            hasTue() {
                return !this.isImmediate && this.hasDay("TUE");
            },
            hasWed() {
                return !this.isImmediate && this.hasDay("WED");
            },
            hasThu() {
                return !this.isImmediate && this.hasDay("THU");
            },
            hasFri() {
                return !this.isImmediate && this.hasDay("FRI");
            },
            hasSat() {
                return !this.isImmediate && this.hasDay("SAT");
            },
            hasSun() {
                return !this.isImmediate && this.hasDay("SUN");
            },

            description() {
                if (this.isImmediate) return "Notifications will be sent as soon as we receive an appropriate mention";

                const formatDay = day => {
                    switch(day) {
                        case 'MON': return "Mondays";
                        case 'TUE': return "Tuesdays";
                        case 'WED': return "Wednesdays";
                        case 'THU': return "Thursdays";
                        case 'FRI': return "Fridays";
                        case 'SAT': return "Saturdays";
                        case 'SUN': return "Sundays";
                    }
                }

                let days = this.notification.days.map(d => formatDay(d)).join(', ');
                if (this.notification.days.length === 7) days = "every day";
                else days = "on " + days;
                let time = this.notification.times.map(t => this.formatTime(t)).join(' and ');
                return "Notifications will be sent out " + days + " at " + time + ", if there are any mentions that match";
            }
        },

        methods: {
            ...mapActions('digests', ['updateNotificationFields']),

            formatTime(time) {
                return `${time.slice(0,2)}:${time.slice(2)}`
            },
            async immediateClicked() {
                this.previous = this.previous || {
                    type: this.notification.type,
                    days: this.notification.days ? Array.from(this.notification.days) : [],
                    times: this.notification.times ? Array.from(this.notification.times) : []
                };

                if (!this.notification.id) {
                    this.$set(this.notification, "type", "NOTIFICATION");
                    if (this.notification.days) this.$set(this.notification, "days", []);
                    if (this.notification.times) this.$set(this.notification, "times", []);
                } else {
                    await this.updateNotificationFields({
                        id: this.notification.id,
                        type: "NOTIFICATION",
                        days: [],
                        times: []
                    });
                }

                this.fireUpdateEvent();
            },

            hasDay(day) {
                return this.notification.days && this.notification.days.includes(day)
            },

            async dayClicked(day) {
                const update = {
                    id: this.notification.id,
                    days: this.notification.days ? Array.from(this.notification.days) : [],
                    times: this.notification.times ? Array.from(this.notification.times) : [],
                };

                if (!this.notification.id) {
                    if (!this.notification.days) this.$set(this.notification, 'days', []);
                    if (!this.notification.times) this.$set(this.notification, 'times', []);
                }

                this.previous = this.previous || {
                    type: this.notification.type,
                    days: this.notification.days ? Array.from(this.notification.days) : [],
                    times: this.notification.times ? Array.from(this.notification.times) : []
                };

                const currentlyImmediate = this.notification.type === "NOTIFICATION";
                // If we're currently immediate (hence, should have no days),
                // this might be an old notification with bad day
                // data that we should be cleared.
                if (currentlyImmediate) update.days = [];

                if (this.hasDay(day)) {
                    update.days = this.notification.days.filter(d => d !== day);
                } else {
                    update.days.push(day);
                }

                if (update.days.length && !update.times.length) {
                    update.times.push("0700");
                }

                update.type = update.days.length ? this.nonImmediateType : "NOTIFICATION";
                if (!this.notification.id) {
                    this.$set(this.notification, "type", update.type);
                    this.$set(this.notification, "days", update.days);
                    this.$set(this.notification, "times", update.times);
                } else {
                    await this.updateNotificationFields(update);
                }

                this.fireUpdateEvent();
            },
            replaceTime(time) {
                this.replace = time;
                this.$refs.times.showMenu = true;
            },
            async removeTime(time) {
                this.replace = null;
                this.previous = this.previous || {
                    type: this.notification.type,
                    days: this.notification.days ? Array.from(this.notification.days) : [],
                    times: this.notification.times ? Array.from(this.notification.times) : []
                };

                if (!this.notification.id) {
                    this.$set(this.notification, "times", this.notification.times.filter(t => t !== time));
                } else {
                    await this.updateNotificationFields({
                        id: this.notification.id,
                        times: this.notification.times.filter(t => t !== time)
                    });
                }

                this.fireUpdateEvent();
            },
            async addTime(time) {
                try {
                    let times = this.notification.times ? Array.from(this.notification.times) : [];
                    if (times.includes(time)) return;

                    this.previous = this.previous || {
                        type: this.notification.type,
                        days: this.notification.days ? Array.from(this.notification.days) : [],
                        times: this.notification.times ? Array.from(this.notification.times) : []
                    };

                    if (this.replace) {
                        times = this.notification.times.filter(t => t !== this.replace);
                    }

                    times.push(time);
                    times.sort();

                    if (!this.notification.id) {
                        this.$set(this.notification, "times", times);
                    } else {
                        await this.updateNotificationFields({
                            id: this.notification.id,
                            times: times
                        });
                    }

                    this.fireUpdateEvent();
                } finally {
                    this.replace = null;
                }
            },
            fireUpdateEvent() {
                // Tell the UI to show busy icon. This won't set a disabled state,
                // though, so will allow the UI to continue being responsive until we send
                // the update event.
                this.$emit("input", true);

                if (this.handler) {
                    clearTimeout(this.handler);
                    this.handler = null;
                }
                this.handler = setTimeout(() => {
                    this.handler = null;
                    this.$emit("update", this.previous)
                    this.$emit("input", false);
                    this.previous = null;
                }, 2000);
            }
        }
    }
</script>


<style scoped lang="sass">
    .notification-time-selector
        .btn-link:not(:hover)
            color: var(--be-colour-muted-text-dark)

    .notification-time-selector--selectors
        display: flex
        align-items: center
        flex-wrap: wrap
    .notification-time-selector__days
        display: flex
        & ::v-deep .btn
            border-radius: 0
        .notification-time-selector--mon ::v-deep .btn
            border-top-left-radius: 3px
            border-bottom-left-radius: 3px
        .notification-time-selector--sun ::v-deep .btn
            border-top-right-radius: 3px
            border-bottom-right-radius: 3px

    .be-button + .notification-time-selector__days
        border-left: 2px solid #333
        margin-left: 2px
        padding-left: 3px

    .notification-time-selector__times
        display: flex
        margin-left: 5px
        .be-tag + .be-tag
            margin-left: 5px

    .notification-time-selector__time
        font-family: var(--number-font-family)
        cursor: pointer
        &--disabled
            cursor: default
    .notification-time-selector--description
        color: var(--be-colour-muted-text-dark)
        font-style: italic


</style>