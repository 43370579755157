<template>
    <div class="sidebar-list dense">
        <template v-if="categories">
            <dashboard-tree-category v-for="c in categories" :key="c.name" :category="c" :value="value" :optional="optional"
                                     @input="$emit('input', $event)"/>
        </template>
        <loading-message v-else message="Fetching your dashboards"/>
    </div>
</template>

<script>
    import DashboardTreeCategory from "./DashboardTreeCategory";
    import LoadingMessage from "../LoadingMessage";

    /**
     * Displays list of dashboards in a tree with click to select.
     */
    export default {
        name: "DashboardTree",

        components: {LoadingMessage, DashboardTreeCategory },

        props: {
            value: Number,      // id of dashboard
            dashboards: Array,
            optional: Boolean
        },

        computed: {
            categories() {
                let list = this.dashboards
                if (!list) return null
                let cats = []
                let seen = { }
                for (let i = 0; i < list.length; i++) {
                    let db = list[i]
                    let key = db.category || ""
                    let cat = seen[key]
                    if (!cat) {
                        seen[key] = cat = { name: key, children: []}
                        cats.push(cat)
                    }
                    cat.children.push(db)
                }
                cats.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? +1 : 0)
                if (cats.length && !cats[0].name) cats[0].name = "Uncategorised"
                return cats
            }
        }
    }
</script>

<style scoped>
</style>