import { render, staticRenderFns } from "./SetupNotificationRow.vue?vue&type=template&id=6294eb18&scoped=true"
import script from "./SetupNotificationRow.vue?vue&type=script&lang=js"
export * from "./SetupNotificationRow.vue?vue&type=script&lang=js"
import style0 from "./SetupNotificationRow.vue?vue&type=style&index=0&id=6294eb18&prod&lang=sass"
import style1 from "./SetupNotificationRow.vue?vue&type=style&index=1&id=6294eb18&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6294eb18",
  null
  
)

export default component.exports