<template>
    <dialog-box class="dashboard-input__dialog"
                width="500px"
                overlay
                @close="$emit('close')"
                :title="title || 'Choose Dashboard'">
        <div>
            <search-input v-model="q" autofocus/>
            <dashboard-tree v-model="selected" :dashboards="dbList" :optional="optional" class="tree dark-scrollbars dark-scrollbars--visible"/>
        </div>
        <template v-slot:buttons>
            <a class="btn cancel" @click="$emit('close')" tooltip="Close (esc)">Cancel</a>
            <a class="btn btn-primary" @click="$emit('input', selected)">Select</a>
        </template>
    </dialog-box>
</template>

<script>

    import SearchInput from "@/components/inputs/SearchInput";
    import DashboardTree from "@/components/inputs/DashboardTree";
    import DialogBox from "@/components/DialogBox";
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapActions, mapState} from "vuex";

    export default {
        store: VuexStore,
        components: { SearchInput, DashboardTree, DialogBox },

        props: {
            title: String,
            optional: Boolean,
            value: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                q: '',
                selected: this.value
            }
        },

        watch: {
            value(v) {
                this.selected = v;
            }
        },

        async created() {
            await this.refreshDashboards();
        },

        computed: {
            ...mapState('dashboards', ['dashboards']),

            dbList() {
                let ans = this.dashboards ?? [];
                if (this.q) {
                    let lq = this.q.toLowerCase()
                    ans = ans.filter(d => d.name.toLowerCase().indexOf(lq) >= 0)
                }
                return ans
            }
        },

        methods: {
            ...mapActions('dashboards', ['refreshDashboards'])
        }
    }

</script>

<style scoped>
    .tree {
        margin-top: 5px;
        min-height: 200px;
        overflow-y: auto;
        max-height: 50vh;
    }

    .dashboard-input__dialog ::v-deep .dialog-body {
        padding: 10px
    }

    .dashboard-input__dialog ::v-deep .dialog-button-bar {
        margin: 10px 0 0;
    }
</style>