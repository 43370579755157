<template>
    <div class="cat" :class="{expanded: expanded}">
        <a class="btn name" @click="expanded = !expanded">
            <i class="icon-right-dir"/> {{category.name}}
        </a>
        <div v-if="expanded" class="children">
            <a v-for="c in category.children" :key="c.id" class="btn" :class="{active: c.id === value}"
               @click="onDashboardClick(c.id)">{{c.name}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardTreeCategory",

        props: {
            value: Number,      // dashboard id
            category: Object,
            optional: Boolean
        },

        data() {
            return {
                expanded: false
            }
        },

        methods: {
            onDashboardClick(id) {
                if (this.value === id) {
                    if (!this.optional) return
                    id = null
                }
                this.$emit('input', id)
            }
        }
    }
</script>

<style scoped>
    .btn {
        margin-right: 0 !important;
    }

    .name i {
        transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    .cat.expanded .name i {
        transform: rotate(90deg);
    }

    .children {
        background: var(--colour-background-black);
    }

    .cat.expanded .children {
        padding-bottom: 4px;
    }

</style>